.marquee-container {
    height: 50px;
    background-color: #0076BD;
}

.marquee-container .child p {
    margin-bottom: 0px;
    font-size: 24px;
    color: white;
    font-weight: 700
}