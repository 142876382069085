/* BlogDetail.css */
.blog-detail-page {
    background-color: #22C8BA; /* Blue background */
    padding: 20px;
    display: flex;
    justify-content: center;
  }
  
  .blog-detail-container {
    background-color: white; /* White background for the blog content box */
    max-width: 800px;
    width: 100%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .blog-detail-header {
    margin-bottom: 20px;
  }
  
  .blog-date {
    color: #888;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .blog-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .blog-subtitle {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
  }
 .blog-content2 img{
  width: 100%;
  height: auto;
 }
  .blog-detail-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .blog-content2 p {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
  }
  
  @media (max-width: 600px) {
    .blog-detail-container {
      padding: 10px;
    }
  
    .blog-title {
      font-size: 20px;
    }
  
    .blog-subtitle, .blog-content2 p {
      font-size: 14px;
    }
  }
  