.cursor {
  cursor: pointer;
}
.f-1 {
  font-size: 1rem;
}
.f-1-1 {
  font-size: 1.1rem;
}
.f-1-2 {
  font-size: 1.2rem;
}
.f-1-5 {
  font-size: 1.5rem;
}
.f-2 {
  font-size: 2rem;
}
.f-3 {
  font-size: 3rem;
}

.f-bold {
  font-weight: bold;
}
.flex-1 {
  flex: 1;
}

.hover:hover {
  color: blueviolet;
}
.hover-cyan:hover {
  color: #1e9ad6;
}
.f-400 {
  font-weight: 400;
}
.f-500 {
  font-weight: 500;
}

/* color */
.color-white {
  color: white;
}
.color-blue-cyan {
  color: #0076bd;
}
.color-grey {
  color: rgba(0, 0, 0, 0.61);
}
/*  */

.w-full {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.w-90 {
  width: 90%;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}
.fade-up {
  animation: fade-up 0.5s linear;
}
.bg-blue {
  background-color: #0076bd;
}
.line-height-3 {
  line-height: 2.4rem;
}
@keyframes fade-up {
  0% {
    transform: translateY(40px);
  }
  25% {
    transform: translateY(30px);
  }
  50% {
    transform: translateY(20px);
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.fade-right {
  animation: fade-right 0.5s linear;
}
@keyframes fade-right {
  0% {
    transform: translateX(-100px);
  }
  25% {
    transform: translateX(-75px);
  }
  50% {
    transform: translateX(-50px);
  }
  75% {
    transform: translateX(-25px);
  }
  100% {
    transform: translateX(0px);
  }
}

@media (max-width:640px){
  .f-2{
    display: flex;
    text-align: center;
  }

  .f-400{
    display: flex;
  }
}

.imp-padd{
  padding-left: 5px;
  padding-right: 5px;
}