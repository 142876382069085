   
.menu_section{
  display: flex;
  flex-wrap: wrap;
  margin: auto; 
  padding: 0 2rem;
  justify-content: center;
}
.menu_card{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all .7s;
  overflow: hidden;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  width: 100%;
  height: 100%;
}
.content-hover {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .7);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}
.menu_card:hover .content-hover{
  height: 100%;
}
.menu_card:hover .title-menu{
  display: none;
}
.menu_img{
  position: relative;
  display: block;
  z-index: -1;
  object-fit: cover;
    width: 100%;
    height: 100%;
}
.title-menu{
  position: absolute;
  color: white;
  margin: auto;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 6px 4px darkslategrey;
}
.custom-align{
  align-items: flex-end;
}
.custom-text-align{
  margin-bottom: 5rem;
}
.a_menu{
  height: max-content;
}
.mobile-landscape {
  width: 50%;
  height: 348px;
}
.potrait{
  width: 25%;
    height: 347px;
    float: left;
    position: relative;
    transition: all .7s;
    overflow: hidden;
}
@media (max-width:640px){
  .menu_section{
    padding: 0 0.5rem;
    margin-top: 2rem;
  }
  .menu_card{
    width: 100%;
    height: 100%;
  }
  .mobile-landscape{
    width: 100%;
    height: 65vw;
  }
  .custom-text-align{
    margin-bottom: 2rem;
  }
  .potrait{
    width: 50%;
    float: left;
    height: 65vw;
  }
  
}

