.home_main {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  position: relative;
  overflow-x: hidden;
}

.school_name {
  font-size: 50px;
  font-weight: 700;
}

.school_name1 {
  font-size: 50px;
  font-weight: 700;
}

.landing_video {
  height: 100vh !important;
  object-fit: cover !important;
}

/* .landing_video {
  margin-top: 11%;
  object-fit: cover !important;
} */

.landing_video>video {
  object-fit: cover;
}

@media(max-width:640px) {
  .landing_video {
    height: auto !important;
    margin-top: 4.7rem;
  }

  .school_name1 {
    font-size: 25px;
    font-weight: 700;
  }

  .school_name {
    font-size: 25px;
    font-weight: 700;
    margin-left: 5px;
  }
}