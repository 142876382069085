.inspired_main{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-top: 2rem;
  background-repeat: no-repeat;
  color:  #101E3F;
  min-height: 280px;
  margin:2rem;
}
.read_more{
  background-color: #101E3F;
  padding: 0.5rem 2rem;
  border-radius: 25px;
}
.read_more:hover{
  background-color: aliceblue;
  color: #101E3F;
  border: 3px solid #101E3F;
  
}
.inspired_title{
  color: #101E3F;
}
.inspired_subtitle{
  color: #27968E;
}
.inspired_button{
  margin-bottom: 0rem;
  margin-top: 4.5rem;
}
@media(max-width:640px){
  .inspired_button{
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }
  .inspired_title{
    width: 90%;
    text-align: center;
    color: #b9c0d0;
  }
  .inspired_subtitle{
    color: #27968E;
    display: flex;
    text-align: center;
    
  }
}
