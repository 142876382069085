/* .awardimg{
    width: 250px;
    height: 200px;
    border: 3px solid rgba(31, 220, 0, 0.93);
    border-radius: 5px;
    filter: drop-shadow(10px 10px 4px rgba(0, 0, 0, 0.25));
}
.boardingimg{
    width: 250px;
    height: 200px;
    border: 3px solid  rgba(255, 140, 34, 0.91);;
    border-radius: 5px;
    filter: drop-shadow(10px 10px 4px rgba(0, 0, 0, 0.25));
    margin: 15px 0;
}
.acscard{
    width: 300px;
    height: 400px;
    max-height: fit-content;
    border: 3px solid rgba(31, 220, 0, 0.93);
    border-radius: 5px;
    box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.25);
}
.acsimg{
    width: 100%;
    height: 60%;
}
.acsimg1{
    width: 100%;
    height: 100%;
}
.ac{
    text-align: center;
    margin: 1rem;
}
.acdetails{
    text-align: center;
    margin: 1rem;
}

@media (max-width: 640px){
    .middlee{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
} */

/*  second  */

/* .awardimg {
    width: 100%;
    height: auto;
    max-width: 250px;
    border: 3px solid rgba(31, 220, 0, 0.93);
    border-radius: 5px;
    filter: drop-shadow(10px 10px 4px rgba(0, 0, 0, 0.25));
} */

/* .boardingimg {
    width: 100%;
    height: auto;
    max-width: 250px;
    border: 3px solid rgba(255, 140, 34, 0.91);
    border-radius: 5px;
    filter: drop-shadow(10px 10px 4px rgba(0, 0, 0, 0.25));
    margin: 15px 0;
} */

/* .acscard {
    width: 90%;
    max-width: 300px;
    height: auto;
    border: 3px solid rgba(31, 220, 0, 0.93);
    border-radius: 5px;
    box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.25);
    margin: 0 auto; 
} */
/* 
.acsimg {
    width: 100%;
    height: 60%;
}

.acsimg1 {
    width: 100%;
    height: 100%;
}

.ac {
    text-align: center;
    margin: 1rem;
}

.acdetails {
    text-align: center;
    margin: 1rem;
}

@media (max-width: 640px) {
    .middlee {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem; 
    }

    .ac, .acdetails {
        font-size: 1rem;
        margin: 0.5rem;
    }

    .awardimg, .boardingimg {
        max-width: 100%;
    }
} */

/* Ensure images are responsive */
.awardimg, .acsimg {
    width: 100%;
    height: auto;
    max-width: 100%;
    object-fit: cover;
  }
  
  .sidebar_container {
    display: flex;
    flex-direction: column;
  }
  
  .main_content {
    width: 100%;
  }
  
  .header {
    color: #1FDC00ED;
    text-align: center;
  }
  
  .admissions_image {
    max-width: 100%;
    height: auto;
  }
  
  .intro {
    margin-top: 1.5rem;
  }
  
  .intro_text {
    font-size: 1rem;
  }
  
  .achievement_section {
    margin-top: 2rem;
  }
  
  .achievement_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .achievement_text {
    text-align: left;
    margin-right: 2%;
  }
  
  .achievement_image img {
    width: 100%;
    height: auto;
    border: 3px solid rgba(31, 220, 0, 0.93);
  }
  
  .award_gallery {
    margin-top: 2rem;
  }
  
  .award_col {
    padding: 0.5rem;
  }
  
  .students_achievements {
    margin-top: 2rem;
  }
  
  .acscard {
    position: relative;
  }
  
  .acsimg {
    width: 100%;
    height: auto;
  }
  
  .ac {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
  }
  
  .acdetails {
    position: absolute;
    bottom: 0;
    left: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .main_content {
      padding: 1rem;
    }
    
    .achievement_content {
      flex-direction: column;
      text-align: center;
    }
    
    .achievement_text {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  
    .award_gallery {
      padding: 0 1rem;
    }
    
    .award_col {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
  