.content_main {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.contentmain_cont {
  display: flex;
  gap: 2rem;
  padding: 1rem 2rem;
  position: relative;
}

.curriculum_main {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
}

.curriculum_card_main {
  position: relative;
  transition: background-color 0.35s;
}

.hover_content {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 0px);
  height: calc(100% - 50px);
  background: rgba(33, 32, 30, 0.8);
  background-size: 100% 100%;
  padding: 15px;
  box-sizing: border-box;
  opacity: 0;
  transition: all 0.7s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hover_content:hover {
  opacity: 1;
}

.card_main {
  width: 250px;
  border: 1px solid #ece4e4;
  position: relative;
  display: inline-block;
  margin: 0 3% 3% 0;
  box-shadow: 5px 5px 7px #dedede;
  vertical-align: top;
  min-height: 425px;
  max-height: 500px;
}

.card_main::before {
  position: absolute;
  content: "";
  height: 2px;
  background-color: #106bab;
  left: 0;
  width: 0%;
  z-index: 999999999999999;
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
  bottom: -2px;
  z-index: 9;
}

.card_main:hover::before {
  width: 100%;
}

.alumni_image {
  width: 100%;
  min-height: 300px;
  max-height: 300px;
}

.alumni_details {
  padding: 1rem;
}

.content_img {
  width: 200px;
  height: 200px;
  box-shadow: 10px 10px 4px 0px #00000040;
}

.content_rect_img {
  width: 300px;
  height: 200px;
  box-shadow: 10px 10px 4px 0px #00000040;
}

.desc_img {
  max-height: 50vh;
  max-width: 50vw;
  margin-top: 1rem;
}

.custom-img {
  margin-top: 1rem;
  width: 50vw;
}

.custom-btn-pdf {
  border: 1.5px solid #0076bd;
  border-radius: 3px;
  width: 200px;
  text-align: center;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 1rem;
  box-shadow: 1px 2px 4px #98a9a94d;
  font-weight: 600;
  transition: all 0.3s ease;
}

.custom-btn-pdf:hover {
  transform: scale(1.1);
}

.award_card {
  height: 200px;
  min-height: auto !important;
  max-height: auto !important;
}

.award_image {
  width: 100%;
  height: 200px;
}

.campus_v {
  height: 400px !important;
}

.campus_v>video {
  aspect-ratio: 20/9;
  object-fit: cover;
}

.circle-div {
  position: absolute;
  right: 3%;
  z-index: -1;
}

.dynamic {
  flex-direction: row-reverse;
}

.nondynamic {
  flex-direction: row;
}

.voice_text {
  margin-left: 12rem;
  margin-top: -11rem;
}

.voice_textt {
  margin-left: 0px;
  margin-top: 1rem;
}

.voicetext {
  margin-left: 0px;
  margin-top: 1rem;
}

.voicetextt {
  margin-top: 1rem;
}

.parents_voicee {
  margin-top: 1.5rem;
  display: flex;
}

.skillrow {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 0;
}

.skillroww {
  display: flex;
  flex-direction: row;
}

/* .skillcol{
  margin-left: 3rem;
} */
.pedagogicards {
  margin-left: 3rem;
}

.skillroww li {
  width: 15rem !important;
}

.pedagogitext {
  margin-top: 2rem;
}

.pedagogi_text {
  margin-left: 50px;
}

.pedagogi_textt {
  max-width: 80%;
  margin-left: 5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.lifeskillimage {
  margin-right: 2rem;
}

.skillcoll {
  margin-right: 5rem;
}

.nccheading {
  font-size: 2rem;
}

.infodiv {
  display: flex;
  flex-direction: row;
}

.transport_image {
  margin-right: 20px;
  margin-left: 20px;
}

.admissions_image {
  width: 4rem;
  margin-right: 1rem;
}

.admissions__image {
  width: 4rem;
}

.skills_voca {
  display: flex;
  align-items: center;
}

.pedagogi_image {
  margin-left: -20px;
  margin-top: -6px;
}

.faculty_info {
  width: 67vw;
}

@media (max-width: 640px) {
  .visionimage {
    width: 50px;
    height: 50px;
  }

  .voicetextt {
    margin-top: 0rem;
  }

  .pedagogi_heading {
    margin-left: -1rem;
  }

  .pedagogi_image {
    margin-left: -18px;
    margin-top: -16px;
  }

  .skills_voca {
    align-items: flex-start;
  }

  .admissions_image {
    width: 50px;
    height: 50px;
  }

  .transport_image {
    margin-right: 0;
    margin-left: 0;
    display: flex;
    justify-content: center;
  }

  .transport {
    flex-direction: column;
  }

  .dynamic {
    display: flex;
    flex-direction: column;
  }

  .nondynamic {
    display: flex;
    flex-direction: column;
  }

  .infodiv {
    display: flex;
    flex-direction: column;
  }

  .nccheading {
    font-size: 2rem;
  }

  .lifeskills {
    margin-left: 2px;
  }

  .lifeskillimage {
    margin-right: 0rem;
  }

  .skillcol {
    margin-left: 0rem;
  }

  .skillcoll {
    margin-right: 0px;
    margin-bottom: 1rem;
  }

  .skillroww {
    display: flex;
  }

  .title {
    margin-left: 6px;
    margin-top: -20px;
  }

  .voicetext {
    margin-left: 0px;
    margin-top: 0rem;
  }

  .pedagogicards {
    margin-left: 0rem;
  }

  .pedagogi_textt {
    margin-left: 0;
    display: flex;
    justify-content: center;
  }

  .pedagogitext {
    margin-top: 0rem;

  }

  .pedagogiheading {
    flex-direction: column;
  }

  .pedagogiimage {
    margin-left: 0rem;
    display: flex;
    justify-content: center;
  }

  .pedagogi_text {
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .contentmain_cont {
    flex-direction: column;
  }

  .skillrow {
    list-style-type: none;
    display: flex;
    flex-direction: column;
  }

  .speech {
    display: flex;
    flex-direction: column;
  }

  .alumni {
    display: flex;
    flex-direction: column;
  }

  .alumnii {
    display: flex;
    flex-direction: column-reverse;
  }

  .alumni_img {
    display: flex;
    justify-content: center;
  }

  .faculty_staff {
    display: flex;
    justify-content: center;
  }

  .faculty_info {
    width: 100%;
  }

  .parents_voice {
    display: flex;
    flex-direction: column;
  }

  .parents_voicee {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 2rem;
  }

  .voice_text {
    margin-left: 0px;
    margin-top: 0.5rem;
  }

  .voice_textt {
    margin-left: 0px;
    margin-top: 0rem;
  }

  .parentimage {
    display: flex;
    justify-content: center;
  }

  .life_skill {
    display: flex;
    flex-direction: column;
  }

  .lifeskill_container {
    display: flex;
    justify-content: center;
    padding-bottom: 35px;
  }

  .students_council {
    display: flex;
    flex-direction: column;
  }

  .news {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .news_card {
    width: 80vw;
  }

  .fade-up {
    display: flex;
  }

  .infra {
    display: flex;
    flex-direction: column;
  }

  .infraa {
    display: flex;
    flex-direction: column-reverse;
  }

  .playground {
    display: flex;
    flex-direction: column;
  }

  .transportimage {
    width: 83vw;
    margin-top: 1rem;
  }

  .resultsrow {
    display: flex;
    justify-content: center;
  }

  .admissionsimage {
    width: 800%;
    margin-top: -35px;
  }

  .admissions__image {
    width: 44px;
  }

  .pedagogidiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .cardss {
    display: flex;
    justify-content: center;
  }
}

.conatct-box {
  width: 300px;
  margin-top: -1.5rem;
  margin-left: -1rem;
}